@import url('https://fonts.googleapis.com/css?family=Red+Hat+Text&display=swap');
/*@import url('https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap');*/
/*@import url('https://fonts.googleapis.com/css?family=Neucha&display=swap');*/
/*@import url('https://fonts.googleapis.com/css?family=Bungee+Inline&display=swap');*/
@import url('https://fonts.googleapis.com/css?family=Sigmar+One&display=swap');
/*@import url('https://fonts.googleapis.com/css?family=Varela&display=swap');*/

body {
  margin: 0;
  font-family: 'Red Hat Text', 'Helvetica Neue';
/*    font-family: 'Permanent Marker', 'Helvetica Neue'; [ok for markup perhaps] */
/*    font-family: 'Varela', 'Helvetica Neue'; [not bad, little thick]  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
@media only screen and (orientation:landscape) {
  body {
    height: 100vw;
    transform: rotate(-90deg);
  }
}
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

